import { BrowserRouter } from 'react-router-dom';
import BaseRouter from './routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import './components/icons/iconModule';
import './assets/scss/custom/app/app.scss';

const queryClient = new QueryClient();
function App(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <BaseRouter {...props} />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
